import { Link } from "react-router-dom";
import styles from "./Breadcrumbs.module.css";

export default function Breadcrumbs({items = []}) {
  return (
    <ol className={styles.Breadcrumbs}>
      <li>
        <Link to="/" className={ styles.breadcrumb_link }>Home</Link>
      </li>
      {items.map((item) => {
        return (
          <li key={ item.path }>
            <span className={styles.separator}>{`>`}</span>
            <Link to={ item.path } className={ styles.breadcrumb_link }>{ item.name }</Link>
          </li>
        )
      })}
    </ol>
  );
}
