import { Link } from "react-router-dom";
import styles from "./EpisodeMini.module.css";
import { RiEye2Line } from "react-icons/ri";
import { getDateTimeLocalFormattedFromUTC } from "../../helpers/dateTime";

export default function EpisodeMini({ episode = {}, podcastId = 0 }) {
  // publication_date comes in UTC time
  const  {id, title, subtitle, publication_date} = episode;
  const { date, time } = getDateTimeLocalFormattedFromUTC(publication_date);
  const formatted_pub_date = `${date} ${time}`;

  return (
    <article className={ styles.Episode }>
      <div className={styles.podcast_info}>
        <Link to={`/podcast/${podcastId}/episode/${id}`} className={styles.podcast_title}>
          <h3>{ title }</h3>
        </Link>
        <div className={styles.pub_date}>{formatted_pub_date}</div>
        <div className={styles.subtitle}>{ subtitle }</div>
      </div>
      <div className={styles.actions}>
        <Link to={`/podcast/${podcastId}/episode/${id}`} className={ `button ` + styles.action }>
          <RiEye2Line/> View
        </Link>
      </div>
    </article>
  );
}
