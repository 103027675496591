import { useState, useEffect } from "react"
import { useParams, useNavigate } from "react-router-dom";
import { getEpisode, editEpisode } from "../../helpers/episodeService"
import { getPodcast } from "../../helpers/podcastService"
import EpisodeForm from "../../components/forms/EpisodeForm"
import Breadcrumbs from "../../components/layout/Breadcrumbs";

export default function EditEpisode(props) {

    let navigate = useNavigate();
    const { podcast_id, episode_id } = useParams();
    const [episode, setEpisode] = useState({});
    const [loaded, setLoaded] = useState(false);
    const [breadcrumbs, setBreadcrumbs] = useState([]);
    const [saveError, setSaveError] = useState('');

    useEffect(() => {
        getEpisode(podcast_id, episode_id).then((episode) => {
            setEpisode(episode);
            setLoaded(true);
        })
    }, [podcast_id, episode_id]);

    useEffect(() => {
        getPodcast(podcast_id).then((podcast) => {
            setBreadcrumbs([{
                path: `/podcast/${podcast.id}`,
                name: podcast.name
            }]);            
        })
    }, [podcast_id]);

    function handleEditEpisode(episodeData) {
        editEpisode(podcast_id, episode_id, episodeData)
        .then(() => {
            navigate(`/podcast/${podcast_id}/episode/${episode_id}`);
        })
        .catch((err) => { 
            setSaveError(err);
        });
    }

    function handleCancel() {
        navigate(`/podcast/${podcast_id}/episode/${episode_id}`);
    }

    if (loaded) {
        return(
            <>
                { breadcrumbs && <Breadcrumbs items={ breadcrumbs } /> }
                <h1>Edit episode</h1>
                <EpisodeForm episode={episode} handleForm={handleEditEpisode} handleCancel={handleCancel} saveError={saveError} editMode></EpisodeForm>
            </>
        )
    }
    return (<div>Loading edit form...</div>)

}
