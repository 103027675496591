import { useState } from "react";
import PodcastForm from '../../components/forms/PodcastForm'
import { createPodcast } from "../../helpers/podcastService";
import { useNavigate } from 'react-router-dom';
import Breadcrumbs from '../../components/layout/Breadcrumbs';

export default function CreatePodcast() {

    let navigate = useNavigate();
    const [saveError, setSaveError] = useState('');

    function handleCreatePodcast(podcastData) {
        createPodcast(podcastData)
        .then((podcast) => {
            navigate(`/podcast/${podcast.id}/`)
        })
        .catch(({status, message}) => {
            setSaveError(`Error during episode creation: ${status} ${message}`);
        });
    }

    function handleCancel() {
        navigate(`/`);
    }

    return(
        <>
            <Breadcrumbs />
            <h1>Create podcast</h1>
            <PodcastForm handleForm={handleCreatePodcast} handleCancel={handleCancel} saveError={saveError} />
        </>
    )
}
