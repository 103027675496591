import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PodcastsList from "../../components/podcast/PodcastsList";
import { getPodcasts } from "../../helpers/podcastService";
import styles from "./Dashboard.module.css";

export default function Dashboard(props) {

    const [podcasts, setPodcasts] = useState([]);
    useEffect(() => {
        getPodcasts().then((podcasts) => {
            setPodcasts(podcasts)
        });
    }, [])

    return(
        <>
            <h2>Dashboard</h2>
            <div className={styles.widget_rail}>
                <div className={styles.widget}>
                    <div className={styles.widget_header}>
                        <h3>My Podcasts</h3>
                        <Link to="/podcast/new" className="button">New Podcast</Link>
                    </div>
                    <PodcastsList podcasts={podcasts} />
                </div>
            </div>
        </>
    )
}
