import { logout } from "../../helpers/authenticationService";
import { Link } from "react-router-dom";
import styles from './Sidebar.module.css';
import { RiDashboard3Line, RiMapPinUserFill, RiLogoutCircleRLine } from "react-icons/ri";

export default function Sidebar({ hideMenu }) {

    function handleLogout(event) {
        event.preventDefault();
        logout();
    }
    return(
        <nav role="menu" className={styles.navbar}>
            <Link to="/" onClick={() => hideMenu()}><RiDashboard3Line /> Dashboard</Link>
            <Link to="profile" onClick={() => hideMenu()}><RiMapPinUserFill /> Profile</Link>
            <a href="/" onClick={handleLogout}><RiLogoutCircleRLine /> Logout</a>
        </nav>
    );
}
