import { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom";
import { getPodcast, getEpisodes } from "../../helpers/podcastService"
import PodcastHeader from "../../components/podcast/PodcastHeader";
import EpisodesList from "../../components/podcast/EpisodesList";
import Breadcrumbs from "../../components/layout/Breadcrumbs";
import { RiAddLine, RiArrowLeftLine, RiEditLine } from "react-icons/ri";

export default function Podcast() {

    const { podcast_id } = useParams();
    const [podcast, setPodcast] = useState({});
    const [episodes, setEpisodes] = useState([]);

    useEffect(() => {
        getPodcast(podcast_id).then((podcast) => {
            setPodcast(podcast);
        })
    }, [podcast_id])

    useEffect(() => {
        getEpisodes(podcast_id).then((episodes) => {
            setEpisodes(episodes);
        })
    }, [podcast_id])

    return (
        <>
            <Breadcrumbs />
            <section>
                <PodcastHeader podcast={ podcast } />
                <div className="action_group">
                    <Link to={`/podcast/${podcast_id}/edit`} className="button">
                        <RiEditLine /> Edit podcast
                    </Link>
                    <Link to={`/podcast/${podcast_id}/episode/new`} className="button">
                        <RiAddLine /> Add episode
                    </Link>
                    <Link to="/" className="button button_cancel">
                        <RiArrowLeftLine /> Go back to Dashboard
                    </Link>
                </div>
                <EpisodesList episodes={ episodes } podcastId={ podcast.id } />
            </section>
        </>
    );
}
