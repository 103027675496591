import { Link } from "react-router-dom";
import styles from "./PodcastsList.module.css"

export default function PodcastsList({ podcasts }) {
  return (
    <div className={styles.PodcastsList}>
      <div className={styles.list_header}>
        <div>Nombre</div>
        <div style={{textAlign: "right"}}>Episodios</div>
      </div>
      { podcasts.map(({id, name, total_episodes}) => (
        <div className={styles.list_row} key={id}>
          <div><Link to={`/podcast/${id}`}>{name}</Link></div>
          <div style={{textAlign: "right"}}>{total_episodes}</div>
        </div>
      )) }
    </div>
  );
}
