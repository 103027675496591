import { useState, useRef, useEffect } from "react";
import { uploadImage, deleteImage, getCategoryTree } from "../../helpers/podcastService";
import SquareImageResponsive from "../image/SquareImageResponsive";
import InlineErrorMessage from "../errorMessages/InlineErrorMessage";
import styles from "./PodcastForm.module.css";

export default function PodcastForm({ podcast, handleForm, handleCancel, saveError, editMode = false, ...props }) {

    const [name, setName] = useState(podcast?.name ?? '');
    const [shortname, setShortname] = useState(podcast?.shortname ?? '');
    const [description, setDescription] = useState(podcast?.description ?? '');
    const [website, setWebsite] = useState(podcast?.website ?? '');
    const [explicit, setExplicit] = useState(podcast?.explicit ?? false);
    const [image, setImage] = useState(podcast?.image?.id ?? '');
    const [copyright, setCopyright] = useState(podcast?.copyright ?? '');
    const [language, setLanguage] = useState(podcast?.language ?? '');
    const [feed_url, setFeedurl] = useState(podcast?.feed_url ?? '');
    const [category, setCategory] = useState(podcast?.category ?? '');
    const [subcategory, setSubcategory] = useState(podcast?.subcategory ?? '');
    const [ownerName, setOwnerName] = useState(podcast?.owner?.name ?? '');
    const [ownerEmail, setOwnerEmail] = useState(podcast?.owner?.email ?? '');
    const [authors, setAuthors] = useState(podcast?.authors ?? [{ name: '', email: '' }]);
    const [imageUrl, setImageUrl] = useState(podcast?.image?.path ?? '');
    const [uploadError, setUploadError] = useState('');
    
    const [categoryTree, setCategoryTree] = useState([]);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [subcategoryOptions, setSubcategoryOptions] = useState([]);

    const imageRef = useRef();

    function optionsCategorySubcategory(categories, category){
        let subcategories = [];
        if(category){
            subcategories = categories[category];
        } else {
            const firstCategory = Object.keys(categories)[0];
            subcategories = categories[firstCategory];
        }

        if(subcategories.length){
            setSubcategoryOptions(subcategories);
        } else {
            setSubcategoryOptions(['None']);
        }
    }

    useEffect(() => {
        getCategoryTree().then(categories => {
            setCategoryOptions(Object.keys(categories));
            setCategoryTree(categories);
            optionsCategorySubcategory(categories, podcast?.category);
        })
    }, [podcast])

    function handleCategoryChange(event) {
        setCategory(event.target.value);
        optionsCategorySubcategory(categoryTree, event.target.value);
    }
    

    function handleSubmit(event) {
        event.preventDefault();
        let podcastData = {
            podcast: {
                name: name,
                shortname: shortname,
                description: description,
                website: website,
                explicit: explicit,
                image_id: image,
                copyright: copyright,
                language: language,
                feed_url: feed_url,
                category: category,
                subcategory: subcategory
            },
            owner: {
                name: ownerName,
                email: ownerEmail
            },
            authors: authors
        }

        handleForm(podcastData);
    }

    function modifyAuthorData(value, index, field) {
        setAuthors((authors) => {
            let temp = [...authors];
            temp[index][field] = value;
            return temp;
        })
    }

    function addAuthorsField() {
        let temp = [...authors];
        temp.push({ name: '', email: '' });
        setAuthors(temp);
    }

    function removeAuthorsField() {
        let temp = [...authors];
        if (authors.length > 1) {
            temp.pop();
        }
        setAuthors(temp);
    }

    function handleUploadImage() {
        if (imageRef.current.files[0]) {
            let image = imageRef.current.files[0];

            // Check image dimensions before upload
            let image_for_dimensions = new Image();
            image_for_dimensions.onload = function (event) {
                console.log(image_for_dimensions.width, image_for_dimensions.height);
                // Enforcing square images between 1400 and 3000 px
                if (image_for_dimensions.width >= 1400 &&
                    image_for_dimensions.width <= 3000 &&
                    image_for_dimensions.width === image_for_dimensions.height) {
                    setUploadError('');
                    uploadImage(image).then((data) => {
                        if (data.error) {
                            setUploadError(data.error);
                        } else {
                            setImage(data.id);
                            setImageUrl(data.path)
                        }
                    })
                        .catch((error) => {
                            setUploadError(`Error during upload. Server said: ${error}`)
                            console.log(error)
                        })
                } else {
                    setUploadError(
                        <>
                            Cover image should be square and it's dimensions between 1400px and 3000px. 
                            Current dimensions: <strong>{image_for_dimensions.width}x{image_for_dimensions.height}px</strong>
                        </>
                    );
                }
            }
            image_for_dimensions.src = URL.createObjectURL(image);
        }
    }

    function handleDeleteImage() {
        if (image) {
            deleteImage(image)
                .then(() => {
                    setImage('');
                    setImageUrl('');
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }

    const authorsForm = [];
    for (let i = 0; i < authors.length; i++) {
        authorsForm.push(
            <div key={i} className="form_group">
                <div className="form_element">
                    <input type="text" name={`authorname${i}`} placeholder="Author Name" value={authors[i].name} required onChange={(event) => modifyAuthorData(event.target.value, i, 'name')} />
                </div>
                <div className="form_element">
                    <input type="text" name={`authoremail${i}`} placeholder="Author Email" value={authors[i].email} required onChange={(event) => modifyAuthorData(event.target.value, i, 'email')} />
                </div>
            </div>
        );
    }

    let imageField = (
        <label className="form_element">
            Cover image (JPG or PNG)
            <input type="file" accept="image/png, image/jpeg" name="myfile" placeholder="Image" ref={imageRef} required onChange={() => handleUploadImage()} />
            {uploadError && <InlineErrorMessage>{uploadError}</InlineErrorMessage>}
        </label>
    );
    if (imageUrl !== '') {
        imageField = (
            <div className="form_element">
                <SquareImageResponsive className={styles.podcast_cover}>
                    <img src={imageUrl} alt="Cover" />
                </SquareImageResponsive>
                {!editMode &&
                <div>
                    <button type="button" onClick={handleDeleteImage} className="button button_secondary">Change image</button>
                </div>
                }
            </div>
        );
    }

    let podcast_form_submit = '';

    if (uploadError) {
        podcast_form_submit = <button type="submit" className="button" disabled>Submit</button>;
    } else {
        podcast_form_submit = <button type="submit" className="button">Submit</button>;
    }

    let podcast_form_cancel = <button type="button" className="button button_cancel" onClick={handleCancel}>Cancel</button>;

    const saveErrorMessage = saveError && <InlineErrorMessage>{ saveError }</InlineErrorMessage>;

    return (
        <div>
            <form className="jarvis_form" onSubmit={handleSubmit}>
                <div className="form_group group_2_1">
                    <div className="left">
                        <div className="form_group">
                            <label className="form_element">
                                Name
                                <input type="text" name="name" maxLength="250" placeholder="Peggy's Podcast" value={name} required onChange={(event) => setName(event.target.value)} />
                            </label>
                            <label className="form_element">
                                Shortname
                                <input type="text" name="shortname" maxLength="50" placeholder="peggyscast" value={shortname} required onChange={(event) => setShortname(event.target.value)} />
                            </label>
                        </div>
                        <label className="form_element">
                            Description
                            <textarea name="description" className="longer" placeholder="In this podcast we check up the state of SHIELD affairs weekly" value={description} required onChange={(event) => setDescription(event.target.value)} />
                        </label>
                        <div className="form_group">
                            <label className="form_element">
                                Website
                                <input type="text" name="website" placeholder="https://podcast.shield.net/" value={website} required onChange={(event) => setWebsite(event.target.value)} />
                            </label>
                            <label className="form_element checkbox">
                                <input type="checkbox" name="explicit" placeholder="Explicit" value={explicit} onChange={(event) => setExplicit(event.target.checked)} />
                                Contains explicit content
                            </label>
                        </div>
                        <div className="form_group">
                            <label className="form_element">
                                Copyright text
                                <input type="text" name="copyright" placeholder="© Peggy Carter" value={copyright} required onChange={(event) => setCopyright(event.target.value)} />
                            </label>
                            <label className="form_element">
                                Language
                                <input type="text" name="language" placeholder="Language" value={language} required onChange={(event) => setLanguage(event.target.value)} />
                            </label>
                        </div>
                        <label className="form_element">
                            Feed URL
                            <input type="text" name="feed_url" placeholder="Feed Url" value={feed_url} required onChange={(event) => setFeedurl(event.target.value)} />
                        </label>
                        <div className="form_group">
                            <label className="form_element">
                                Category
                                <select name="category" value={category} required onChange={handleCategoryChange}>
                                    {categoryOptions.map((option) => {
                                        return <option key={option} value={option}>{option}</option>
                                    })}
                                </select>
                            </label>
                            <label className="form_element">
                                Subcategory
                                <select name="subcategory" value={subcategory} required onChange={(event) => setSubcategory(event.target.value)}>
                                    {subcategoryOptions.map((option) => {
                                        return <option key={option} value={option}>{option}</option>
                                    })}
                                </select>
                            </label>
                        </div>
                        {/* Owner Data  */}
                        <div className="form_group">
                            <label className="form_element">
                                Owner name
                                <input type="text" name="ownername" placeholder="SHIELD Comms LTD." value={ownerName} required onChange={(event) => setOwnerName(event.target.value)} />
                            </label>
                            <label className="form_element">
                                Owner email
                                <input type="text" name="owneremail" placeholder="podcastowner@shieldcomms.com" value={ownerEmail} required onChange={(event) => setOwnerEmail(event.target.value)} />
                            </label>
                        </div>
                        {/* Authors Data */}
                        <div className="composite_group">
                            Authors
                            {authorsForm}
                            <div className={styles.authors_actions}>
                                <button type="button" value="Add" className="button button_secondary" onClick={addAuthorsField}>Add author</button>
                                <button type="button" value="Remove" className="button button_secondary" onClick={removeAuthorsField}>Remove last author</button>
                            </div>
                        </div>
                    </div>
                    <div className="right">
                        { imageField }
                    </div>
                    { saveErrorMessage }
                </div>
                <div className="form_actions">
                    { podcast_form_submit }
                    { podcast_form_cancel }
                </div>
            </form>
        </div>
    );
}
