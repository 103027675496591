/**
 * Gets a Date string YYYY-MM-DD HH:MM (assumed UTC), and transforms
 * it in two string parts: Date (YYYY-MM-DD) and Time (HH:MM) in Localtime
 */
export function getDateTimePartsLocalFromUTC(utc_datetime) {
  const date = new Date(Date.parse(`${utc_datetime}:00 UTC`));
  const day_part = `${date.getFullYear()}-` + 
  `${(date.getMonth() + 1).toString().padStart(2,"00")}-` +
  `${date.getDate().toString().padStart(2,"00")}`;
  const time_part = `${date.getHours().toString().padStart(2,"00")}:` + 
  `${date.getMinutes().toString().padStart(2,"00")}`;

  return {
    date: day_part,
    time: time_part
  }  
}

/**
 * Gets a Date string YYYY-MM-DD HH:MM (assumed locale), and transforms
 * it in two string parts: Date (YYYY-MM-DD) and Time (HH:MM) in UTC
 */
 export function getDateTimePartsUTCFromLocal(locale_datetime) {
  const date = new Date(Date.parse(locale_datetime));
  const day_part = `${date.getUTCFullYear()}-` + 
  `${(date.getUTCMonth() + 1).toString().padStart(2,"00")}-` +
  `${date.getUTCDate().toString().padStart(2,"00")}`;
  const time_part = `${date.getUTCHours().toString().padStart(2,"00")}:` + 
  `${date.getUTCMinutes().toString().padStart(2,"00")}`;

  return {
    date: day_part,
    time: time_part
  }  
}

/**
 * Returns current local date (YYYY-MM-DD) and time (HH:MM) in an object
 */
export function getDateTimePartsLocalNow() {
  const date = new Date();

  const day_part = `${date.getFullYear()}-` + 
  `${(date.getMonth() + 1).toString().padStart(2,"00")}-` +
  `${date.getDate().toString().padStart(2,"00")}`;
  const time_part = `${date.getHours().toString().padStart(2,"00")}:` + 
  `${date.getMinutes().toString().padStart(2,"00")}`;

  return {
    date: day_part,
    time: time_part
  } 
}

/**
 * Returns current local date and time formatted in current locale
 */
 export function getDateTimeLocalFormattedFromUTC(utc_datetime) {
  const date = new Date(Date.parse(`${utc_datetime}:00 UTC`));
  const day_part = date.toLocaleDateString(undefined, { year:'numeric', month: '2-digit', day: '2-digit' });
  const time_part = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

  return {
    date: day_part,
    time: time_part
  } 
}
