import { useState, useEffect } from "react"
import { useParams, Link } from "react-router-dom"
import Breadcrumbs from "../../components/layout/Breadcrumbs"
import EpisodePlayer from "../../components/player/EpisodePlayer"
import { getEpisode } from "../../helpers/episodeService"
import { getPodcast } from "../../helpers/podcastService"
import { getDateTimeLocalFormattedFromUTC } from "../../helpers/dateTime"
import styles from "./Episode.module.css"
import { RiArrowLeftLine, RiEditLine } from "react-icons/ri";

export default function Episode() {

    const { podcast_id, episode_id } = useParams()
    const [episode, setEpisode] = useState({})
    const [breadcrumbs, setBreadcrumbs] = useState([])

    useEffect(() => {
        getEpisode(podcast_id, episode_id).then((episode) => {
            setEpisode(episode);
        })       
    }, [podcast_id, episode_id])

    useEffect(() => {
        getPodcast(podcast_id).then((podcast) => {
            setBreadcrumbs([{
                path: `/podcast/${podcast.id}`,
                name: podcast.name
            }]);            
        })
    }, [podcast_id])

    const { title, subtitle, summary, long_summary, media, publication_date } = episode;
    const { date, time } = getDateTimeLocalFormattedFromUTC(publication_date);
    const formatted_pub_date = `${date} ${time}`;

    return (
        <>
        { breadcrumbs && <Breadcrumbs items={ breadcrumbs } /> }
        <article>
            <h1 className={ styles.title }>{ title }</h1>
            <div className={ styles.subtitle }>{ subtitle }</div>
            <div className={ styles.publication_date }>{ formatted_pub_date }</div>
            <div className={ styles.summary }>{ summary }</div>
            <div className={ styles.long_summary }>{ long_summary }</div>
            { media && <EpisodePlayer className={ styles.episode_player_page } media={ media } /> }
            <div className="action_group">
                    <Link to={`/podcast/${podcast_id}/episode/${ episode_id }/edit`} className="button">
                        <RiEditLine /> Edit episode
                    </Link>
                    <Link to={`/podcast/${podcast_id}`} className="button button_cancel">
                        <RiArrowLeftLine /> Go back to Podcast
                    </Link>
                </div>
        </article>
        </>
    );
}
