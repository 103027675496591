import { logout } from "./authenticationService";

export default function handleResponse(response) {
    if (response.ok) {
        return response.json()
        .then((data) => {return data;})
        .catch(error => {console.log(error)});
    }
    else {
        if (response.status === 401) {
            logout();
        }
        return Promise.reject({status: response.status, message: response.statusText});
    }
}
