import EpisodeMini from "./EpisodeMini";
import styles from "./EpisodesList.module.css"

export default function EpisodesList({ episodes = [], podcastId = 0 }) {
  return (
    <div>
      <h2>Episodes</h2>
      <div className={styles.list_wrapper}>
        {episodes.map((item) => (
          <EpisodeMini episode={ item } podcastId={ podcastId } key={ item.id }/>
        ))}
      </div>
    </div>
  );
}
