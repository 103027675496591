import { useState, useEffect } from "react";
import { getUser } from "../../helpers/userService";

export default function Profile(props) {

    const [user, setUser] = useState({});

    useEffect(() => {
        getUser().then((user) => {
            setUser(user);
        })
    }, [])

    return(
        <>
            <h1>Profile Page</h1>
            <div>{user.username}</div>
        </>
    )
}