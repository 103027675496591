import { get, post, put, postFileForm, deleteCall } from "./requestService";

export function getPodcasts() {
    const path = '/podcasts';
    return get(path).catch(error => {throw error});
}

export function getPodcast(podcast_id) {
    const path = '/podcast/' + podcast_id;
    return get(path).catch(error => {throw error});
}

export function getEpisodes(podcast_id) {
    const path = "/podcast/" + podcast_id + "/episodes"
    return get(path).catch(error => {throw error});
}

export function createPodcast(podcastData) {
    const path = "/podcast";
    return post(path, podcastData).catch(error => {throw error});
}

export function editPodcast(podcast_id, podcastData) {
    const path = "/podcast/" + podcast_id
    return put(path, podcastData).catch(error => {throw error});
}

export function uploadImage(image) {
    const formData = new FormData();
    formData.append("image", image, image.name);
    const path = "/image/upload";
    return postFileForm(path, formData).catch(error => {throw error});
}

export function deleteImage(image_id) {
    const path = "/image/" + image_id + "/delete";
    return deleteCall(path).catch(error => {throw error});
}

export function getCategoryTree() {
    const path = "/categories";
    return get(path).catch(error => {throw error})
}
