import { useState } from "react";
import { Outlet } from "react-router-dom";
import { Link } from "react-router-dom";
import Sidebar from "../sidebar/Sidebar";
import styles from "./Layout.module.css";
import logo from "../../assets/JarvisLogo.svg";
import { RiCloseLine, RiMenu2Line } from "react-icons/ri";

export default function Layout(props) {

    const [menu, setMenu] = useState(false)

    function showMenu () {
        setMenu(true);
    }

    function hideMenu() {
        setMenu(false);
    }

    return(
        <div className={styles.content_wrapper}>
            <header className={styles.header}>
                <Link to="/" className={styles.home_link}><h1>
                    <img src={logo} alt="Jarvis" className={ styles.logo }/>
                </h1></Link>
            </header>
            <div onClick={showMenu} className={styles.open_button}><RiMenu2Line /></div>
            <aside className={menu ? styles.sidebar : `${styles.sidebar} ${styles.hidden_sidebar}`}>
                <div onClick={hideMenu} className={styles.close_menu}><RiCloseLine /></div>
                <Sidebar hideMenu={ hideMenu }/>
            </aside>
            <main className={styles.main}>
                <Outlet/>
            </main>
        </div>
    )
}
