import { useState, useRef } from "react";
import { uploadMedia, deleteMedia } from "../../helpers/episodeService";
import { getDateTimePartsUTCFromLocal, getDateTimePartsLocalNow, getDateTimePartsLocalFromUTC } from "../../helpers/dateTime";
import EpisodePlayer from "../player/EpisodePlayer";
import InlineErrorMessage from "../errorMessages/InlineErrorMessage";

export default function EpisodeForm({episode, handleForm, handleCancel, saveError, editMode = false, ...props}) {

    const [title, setTitle] = useState(episode?.title ?? '');
    const [subtitle, setSubtitle] = useState(episode?.subtitle ?? '');
    const [summary, setSummary] = useState(episode?.summary ?? '');
    const [longSummary, setLongSummary] = useState(episode?.long_summary ?? '');
    const [media, setMedia] = useState(episode?.media ?? '');

    const [mediaUrl, setMediaUrl] = useState(episode?.media?.url ?? '');
    const [mediaType, setMediaType] = useState(episode?.media?.type ?? '');
    const [uploadError, setUploadError] = useState('');
    
    let dateTimeParts = {
        date: '1970-01-01',
        time: '00:00',
    };
    const publication_date = episode?.publication_date ?? '';
    if(publication_date){
        dateTimeParts = getDateTimePartsLocalFromUTC(publication_date);
    } else {
        dateTimeParts = getDateTimePartsLocalNow();
    }

    const initialPubDate = dateTimeParts.date;
    const initialPubTime = dateTimeParts.time;

    const [pubDate, setPubDate] = useState(initialPubDate);
    const [pubTime, setPubTime] = useState(initialPubTime)
    const mediaRef = useRef();

    function handleSubmit(event) {
        event.preventDefault();
        // Convert localtime to UTC
        const { date, time } = getDateTimePartsUTCFromLocal(`${pubDate} ${pubTime}`);
        const publication_date = `${date} ${time}`;

        let episodeData = {
            title: title,
            subtitle: subtitle,
            summary: summary,
            long_summary: longSummary,
            media_id: media,
            publication_date: publication_date,
        }
        
        handleForm(episodeData);
    }

    function handleUploadMedia() {
        if (mediaRef.current.files[0]) {
            let mediaData = mediaRef.current.files[0];
            uploadMedia(mediaData).then((data) => {
                console.log('ENTRA THEN');
                if (data.error) {
                    setUploadError(data.error);
                } else {
                    setUploadError('');
                    setMedia(data.id);
                    setMediaUrl(data.url);
                    setMediaType(data.type);
                }
            })
            .catch(({name, message}) => {
                setUploadError(`Error during file upload, please check it is a valid MP4 audio file. (${name}: ${message})`);
            })
        }
    }

    function handleDeleteMedia() {
        if (media) {
            deleteMedia(media)
            .then(() => {
                setMedia('');
                setMediaUrl('');
                setMediaType('');
            })
            .catch((error) => {
                console.log(error)
            })
        }
    }

    let mediaField = (
        <div className="form_element">
            Audio file (MP4 Only)
            <input type="file" name="myfile" accept="audio/mp4, video/mp4" placeholder="Media" ref={mediaRef} required onChange={() => handleUploadMedia()} />
        </div>
    );
    console.log('Upload error:', uploadError);
    if (mediaUrl !== '') {
        if(!editMode) {
            mediaField = (
                <div className="form_element">
                    Current audio file
                    <div className="control_group">
                        <EpisodePlayer media={{url: mediaUrl, type: mediaType}} />
                        <button onClick={handleDeleteMedia} className="button button_secondary">Change Audio file</button>
                    </div>
                </div>
            );
        } else {
            mediaField = (
                <div className="form_element">
                    Current audio file
                    <EpisodePlayer media={{url: mediaUrl, type: mediaType}} />
                </div>
            );
        }
    }

    const uploadErrorMessage = uploadError && <InlineErrorMessage>{ uploadError }</InlineErrorMessage>;
    const saveErrorMessage = saveError && <InlineErrorMessage>{ saveError }</InlineErrorMessage>;

    const long_summary = `In this episode our hosts will explore two unresolved misteries and a third that was managed by SHIELD field team. `+
    `\nToday Special Guests are agents Melinda May and Phil Coulson, the latter of the duo recently came back from Tahiti. \nInsists that it's a Wonderful Place. We'll see.`;

    return (
        <div>
            <form className="jarvis_form" onSubmit={handleSubmit}>
                <div className="form_group">
                    <label className="form_element">
                        Title
                        <input type="text" name="title" placeholder="The Awesome Episode Title" maxLength="250" value={title} required onChange={(event) => setTitle(event.target.value)} />
                    </label>
                    <label className="form_element">
                        Subtitle
                        <input type="text" name="subtitle" placeholder="Another episode" maxLength="250" value={subtitle} required onChange={(event) => setSubtitle(event.target.value)} />
                    </label>
                </div>
                <label className="form_element">
                    Summary
                    <textarea name="summary" placeholder="Mysteries and intrigue in today's episode" value={summary} required onChange={(event) => setSummary(event.target.value)} />
                </label>
                <label className="form_element">
                    Long Summary
                    <textarea name="long_summary" placeholder={long_summary} className="longer" value={longSummary} required onChange={(event) => setLongSummary(event.target.value)} />
                </label>
                <div className="form_group">
                    <label className="form_element">
                        Publication date
                        <input type="date" name="publication_date_date" value={pubDate} required onChange={(event) => setPubDate(event.target.value)} />
                    </label>
                    <label className="form_element">
                        Publication time
                        <input type="time" name="publication_date_time" value={pubTime} required onChange={(event) => setPubTime(event.target.value)} />
                    </label>
                </div>
                { mediaField }
                { uploadErrorMessage }
                { saveErrorMessage }
                <div className="form_actions">
                    <button type="submit" className="button">Submit</button>
                    <button type="button" className="button button_cancel" onClick={handleCancel}>Cancel</button>
                </div>
            </form>
        </div>
    );
}
