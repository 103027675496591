import handleResponse from "./handleResponse";
import { getUserToken } from "./authenticationService";

const baseUrl = process.env.REACT_APP_PODCASTS_URL;

export function get(urlPath, params) {

    const url = new URL(urlPath, baseUrl);
    for (const key in params) {
        url.searchParams.append(key, params[key])
    }

    return fetch(url,{ method: 'GET', headers: getHeaders() })
    .then((response) => {return handleResponse(response)})
    .catch(error => {throw error});
}

export function post(urlPath, params) {
    const url = new URL(urlPath, baseUrl);
    return fetch(url,{ method: 'POST', headers: getHeaders({"Content-Type":"application/json"}), body: JSON.stringify(params) })
    .then((response) => {return handleResponse(response)})
    .catch(error => {throw error});
}

export function deleteCall(urlPath) {
    const url = new URL(urlPath, baseUrl);
    return fetch(url,{ method: 'DELETE', headers: getHeaders() })
    .then((response) => {return handleResponse(response)})
    .catch(error => {throw error});
}

export function put(urlPath, params) {
    const url = new URL(urlPath, baseUrl);
    return fetch(url,{ method: 'PUT', headers: getHeaders({"Content-Type":"application/json"}), body: JSON.stringify(params) })
    .then((response) => {return handleResponse(response)})
    .catch(error => {throw error});
}

export function postForm(urlPath, formData) {
    const url = new URL(urlPath, baseUrl);
    const data = new URLSearchParams(formData)

    return fetch(url,{ method: 'POST', body: data })
    .then((response) => {return handleResponse(response)})
    .catch(error => {throw error});
}

export function postFileForm(urlPath, formData) {
    const url = new URL(urlPath, baseUrl);

    return fetch(url,{ method: 'POST', headers: getHeaders("multipart/form-data"), body: formData })
    .then((response) => {return handleResponse(response)})
    .catch(error => {throw error});
}

function getHeaders(custom_headers) {
    let headers = {
        Authorization: 'Bearer ' + getUserToken(),
        ...custom_headers
    }
    return headers;
}