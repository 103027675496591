import { useState, useEffect } from "react"
import { useParams, useNavigate } from "react-router-dom";
import { getPodcast, editPodcast } from "../../helpers/podcastService"
import PodcastForm from "../../components/forms/PodcastForm"
import Breadcrumbs from "../../components/layout/Breadcrumbs";

export default function EditPodcast() {

    let navigate = useNavigate();
    const { podcast_id } = useParams();
    const [podcast, setPodcast] = useState({});
    const [loaded, setLoaded] = useState(false);
    const [saveError, setSaveError] = useState('');

    useEffect(() => {
        getPodcast(podcast_id).then((podcast) => {
            setPodcast(podcast);
            setLoaded(true);
        })
    }, [podcast_id])

    function handleEditPodcast(podcastData) {
        editPodcast(podcast_id, podcastData)
        .then(() => {
            navigate(`/podcast/${podcast_id}`);
        })
        .catch(({status, message}) => {
            setSaveError(`Error during episode creation: ${status} ${message}`);
        });
    }

    function handleCancel() {
        navigate(`/podcast/${podcast_id}`);
    }

    if (loaded) {
        return(
            <>
                <Breadcrumbs />
                <h1>Edit podcast</h1>
                <PodcastForm podcast={podcast} handleForm={handleEditPodcast} handleCancel={handleCancel} saveError={saveError} editMode={true} />
            </>
        )
    }
    return (<div>Loading edit form...</div>)

}
