import styles from "./PodcastHeader.module.css"
import { RiGlobeLine, RiHashtag, RiRssLine } from "react-icons/ri";
import { RiUser3Line } from "react-icons/ri";
import { MdOutlineExplicit } from "react-icons/md";
import PersonLinkedMail from "../person/PersonLinkedMail";

export default function PodcastHeader({ podcast }) {
    const { 
        image, name, description = '', 
        authors, owner, category, subcategory, 
        feed_url, website, explicit } = podcast;
    return (
        <div className={ styles.PodcastHeader }>
            <div className={ styles.left_side }>
                <div className={ styles.title }>
                    <h1 className={ styles.podcast_name }>{ name }</h1>
                </div>
                <div className={ styles.icons_data}>
                    <div className={ styles.category }><RiHashtag /> { category }, { subcategory }</div>
                    {explicit && 
                        <div className={styles.explicit}><MdOutlineExplicit /> Explicit Content</div>
                    }
                    { 
                        owner && (
                            <div className={ styles.owner }>
                            <RiUser3Line /><strong>Owner</strong>:&nbsp;<PersonLinkedMail person={owner} />
                            </div>)
                    }
                    { 
                        authors && (
                            <div className={ styles.authors }>
                                <RiUser3Line /> <strong>Author(s)</strong>: {" "}
                                {authors.map((author, idx) => {
                                    const separator = idx < authors.length-1 ? `, ` : '';
                                    return <span key={idx}>&nbsp;<PersonLinkedMail person={author} />{ separator }</span>
                                })}
                            </div>)
                    }
                    <div className={ styles.feed }>
                        <RiRssLine /> <strong>Feed</strong>:&nbsp;<a href={ feed_url } target="_blank" rel="noreferrer">{ feed_url }</a>
                    </div>
                    <div className={ styles.website }>
                        <RiGlobeLine /> <strong>Website</strong>:&nbsp;<a href={ website } target="_blank" rel="noreferrer">{ website }</a>
                    </div>
                </div>
                <div className={ styles.description }>{ description.split('\n').map((str, idx) => <p key={idx}>{str}</p>) }</div>
            </div>
            {image && (<img className={styles.cover} src={ image.path } alt="Podcast Cover" />)}
        </div>
    );
}
            