import Login from './components/login/Login';
import { Routes, Route } from 'react-router-dom';

import Dashboard from './pages/dashboard/Dashboard';
import Profile from './pages/profile/Profile';
import Layout from './components/layout/Layout';
import Podcast from './pages/podcast/Podcast';
import Episode from './pages/episode/Episode';
import CreatePodcast from './pages/podcast/CreatePodcast';
import EditPodcast from './pages/podcast/EditPodcast';
import CreateEpisode from './pages/episode/CreateEpisode';
import EditEpisode from './pages/episode/EditEpisode';

function App() {

  return (
      <Login>
        <Routes>
          <Route element={<Layout/>}>
            <Route path="/" element={<Dashboard/>}/>
            <Route path="profile" element={<Profile />} />
            <Route path="podcast/:podcast_id" element={<Podcast />}/>
            <Route path="podcast/:podcast_id/edit" element={<EditPodcast />}/>
            <Route path="podcast/new" element={<CreatePodcast/>} />
            <Route path="podcast/:podcast_id/episode/:episode_id" element={<Episode />} />
            <Route path="podcast/:podcast_id/episode/:episode_id/edit" element={<EditEpisode />} />
            <Route path="podcast/:podcast_id/episode/new" element={<CreateEpisode />} />
          </Route>
        </Routes>
      </Login>
  );
}

export default App;
