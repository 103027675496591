import { useState } from 'react'
import { login, isLoggedIn } from '../../helpers/authenticationService';
import styles from './Login.module.css';
import { RiLoginCircleFill } from "react-icons/ri";
import logo from "../../assets/JarvisLogo.svg";

export default function Login({ children }) {

    const [loggedIn, setLoggedIn] = useState(isLoggedIn())
    const [logginError, setLogginError] = useState('')
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    function handleSubmit(event) {
        event.preventDefault();
        
        login(username, password)
        .then(() =>{setLoggedIn(isLoggedIn())})
        .catch(error => setLogginError(error.message));
    }

    if (!loggedIn) {
        return(
            <div className={styles.login_wrapper}>
                <div className={styles.login_form}>
                    <img src={logo} alt="Jarvis" className={ styles.login_form_title }/>
                    <form onSubmit={handleSubmit} className={styles.form}>
                        <input className={styles.form_element_input} type="text" name="username" placeholder="Username" required onChange={(event) => setUsername(event.target.value)}/>
                        <input className={styles.form_element_input} type="password" name="password" placeholder="Password" required onChange={(event) => setPassword(event.target.value)}/>
                        {logginError && <div className={styles.error_message}>{logginError}</div>}
                        <button className={styles.form_element_submit} type="submit"><RiLoginCircleFill /> Log in</button>
                    </form>
                </div>
            </div>
        )
    }
    else {
        return(
            <>{ children }</>
        )
    }
}
