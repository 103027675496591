import { get, post, put, postFileForm, deleteCall } from "./requestService";

export function getEpisode(podcast_id, episode_id) {
    const path = "/podcast/" + podcast_id + "/episode/" + episode_id;
    return get(path).catch(error => {throw error});
}

export function createEpisode(podcast_id, episodeData) {
    const path = "/podcast/" + podcast_id + "/episode/add";
    return post(path, episodeData).catch(error => {throw error});
}

export function editEpisode(podcast_id, episode_id, episodeData) {
    const path = "/podcast/" + podcast_id + "/episode/" + episode_id
    return put(path, episodeData).catch(error => {throw error});
}

export function uploadMedia(media) {
    const formData = new FormData();
    formData.append("file", media, media.name);
    const path = "/media/upload";
    return postFileForm(path, formData).catch(error => {throw error});
}

export function deleteMedia(media_id) {
    const path = "/media/" + media_id + "/delete";
    return deleteCall(path).catch(error => {throw error});
}