import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom"
import EpisodeForm from "../../components/forms/EpisodeForm"
import Breadcrumbs from "../../components/layout/Breadcrumbs";
import { createEpisode } from "../../helpers/episodeService";
import { getPodcast } from "../../helpers/podcastService"

export default function CreateEpisode() {
    const { podcast_id } = useParams();
    let navigate = useNavigate();
    const [breadcrumbs, setBreadcrumbs] = useState([]);
    const [saveError, setSaveError] = useState('');

    function handleCreateEpisode(episodeData) {
        createEpisode(podcast_id, episodeData)
        .then(() => {
            navigate(`/podcast/${podcast_id}/`);
        })
        .catch(({status, message}) => {
            setSaveError(`Error during episode creation: ${status} ${message}`);
        });
    }

    function handleCancel() {
        navigate(`/podcast/${podcast_id}`);
    }

    useEffect(() => {
        getPodcast(podcast_id).then((podcast) => {
            setBreadcrumbs([{
                path: `/podcast/${podcast.id}`,
                name: podcast.name
            }]);            
        })
    }, [podcast_id])

    return(
        <>
            { breadcrumbs && <Breadcrumbs items={ breadcrumbs } /> }
            <h1>Create episode</h1>
            <EpisodeForm handleForm={handleCreateEpisode} handleCancel={handleCancel} saveError={saveError} />
        </>
    )
}
