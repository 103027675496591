import { postForm } from "./requestService";

export function login(username, password) {

    const formData = new FormData();
    formData.append("username", username);
    formData.append("password", password);
    const url = '/token';

    return postForm(url, formData)
    .then((data) => {
        localStorage.setItem('access_token', data.access_token);
    })
    .catch(error => {
        if (error.status === 401) {
            throw new Error("Wrong username or password.");
        }
        else {
            throw new Error("Internal server error. ");
        }
    });    
}

export function logout() {
    localStorage.setItem('access_token', '');
    window.location = '/';
}

export function isLoggedIn() {
    const token = localStorage.getItem('access_token');
    return  token !== null && token !== '';
}

export function getUserToken() {
    return localStorage.getItem('access_token');
}
