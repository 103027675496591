import styles from "./EpisodePlayer.module.css";

export default function EpisodePlayer({ media, className = '' }) {
  const { url, type } = media;
  return (
    <div className={ `${styles.EpisodePlayer} ${className}` }>
      <audio controls>
        <source src={ url } type={ type } />
          Your browser does not support the audio element.
      </audio>
    </div>
  );
}
